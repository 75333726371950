import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
import i18n from '../../../../locale';
import SectionWrapper from '../../../../components/SectionWrapper';
import Feature from './Feature';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        vc1: imageSharp(fluid: {
          originalName : {eq: "Dealership-Feature-1.jpg"}
        }) {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        vc2: imageSharp(fluid: {
          originalName : {eq: "Dealership-Feature-2.jpg"}
        }) {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        vc3: imageSharp(fluid: {
          originalName : {eq: "Dealership-Feature-3.jpg"}
        }) {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        vc4: imageSharp(fluid: {
          originalName : {eq: "Dealership-Feature-4.jpg"}
        }) {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        vc5: imageSharp(fluid: {
          originalName : {eq: "Dealership-Feature-5.jpg"}
        }) {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        } 
      }
    `}
    render={({ vc1, vc2, vc3, vc4, vc5 }) => (
      <SectionWrapper id={i18n('_hash:for-you')}>
        <Grid item container xs={12} md={12} xl={12}
          alignContent='center'
          justify='center'
          className={styles.container}>
          <Feature title={i18n('Advanced remote data access')}
            text={i18n('Specific data coverage to the vehicles that your workshop is an expert on. Providing new insights that were not available before.')}
            image={vc1}
            layout={{
              xs: 12,
              sm: 6,
              md: 6,
              lg: 6,
              xl: 6
            }}/>
          <Feature title={i18n('Timely customer services')}
            text={i18n('Build a closer relationship with customers by having more possibilities to coordinate maintenance and repair.')}
            image={vc2}
            layout={{
              xs: 12,
              sm: 6,
              md: 6,
              lg: 6,
              xl: 6
            }}/>
          <Feature title={i18n('Track agreements')}
            text={i18n('A unique way to track service and leasing agreements making it easier to manage operations and communication with customers.')}
            image={vc3}/>
          <Feature title={i18n('Important notifications')}
            text={i18n('Leverage the power of connected car data to be notified when important events occur. Increase productivity and optimize the business.')}
            image={vc4}/>
          <Feature title={i18n('Integrate to other systems')}
            text={i18n('Easy to implement integrations to other relevant systems used by your departments.')}
            image={vc5}/>
        </Grid>
      </SectionWrapper>
    )}/>
);